import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Menu, MapPin } from 'lucide-react';
import axios from 'axios';
import MobileMenu from './MobileMenu';

const Header = () => {
  const [showHeader, setShowHeader] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [user, setUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showLocationBar, setShowLocationBar] = useState(false);
  const userLocation = "Área de la Bahía";
  const locationTimerRef = useRef(null);
  const navigate = useNavigate();
  
  const API_URL = 'https://findout-adf55aa841e8.herokuapp.com/api/login/';

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('token');
      if (!token) return;

      try {
        const config = {
          headers: {
            'Authorization': `Token ${token}`
          }
        };

        const response = await axios.get(API_URL, config);
        setUser(response.data);
      } catch (err) {
        console.error('Error fetching user data:', err);
        localStorage.removeItem('token');
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      
      if (currentScrollY > lastScrollY && currentScrollY > 100) {
        setShowHeader(false);
      } else {
        setShowHeader(true);
      }
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleLocationBar = () => {
    setShowLocationBar(!showLocationBar);
    
    // Limpiar cualquier temporizador existente
    if (locationTimerRef.current) {
      clearTimeout(locationTimerRef.current);
    }
    
    // Si estamos mostrando la barra, configuramos un temporizador para ocultarla después de 3 segundos
    if (!showLocationBar) {
      locationTimerRef.current = setTimeout(() => {
        setShowLocationBar(false);
      }, 3000);
    }
  };

  // Limpiar el temporizador cuando el componente se desmonte
  useEffect(() => {
    return () => {
      if (locationTimerRef.current) {
        clearTimeout(locationTimerRef.current);
      }
    };
  }, []);

  return (
    <>
      <header
        className={`fixed top-0 left-0 w-full bg-slate-900 dark:bg-gray-900 shadow-md dark:shadow-gray-900/50 z-50 font-['Poppins',sans-serif] transition-all duration-500 ease-in-out ${
          showHeader ? 'transform translate-y-0' : 'transform -translate-y-full'
        }`}
      >
        <nav className="container mx-auto flex justify-between items-center h-16 px-6">
          {/* Menu Button */}
          <button
            onClick={toggleMenu}
            className="text-white hover:opacity-80 transition-opacity focus:outline-none"
            aria-label="Toggle menu"
          >
            <Menu className="w-6 h-6" />
          </button>
          
          {/* Logo */}
          <div className="flex justify-center flex-1">
            <Link to="/" className="relative">
              <img
                src="/logoFindout.webp"
                alt="Logo"
                className="h-6 object-contain dark:brightness-110 transition-all"
              />
            </Link>
          </div>

          {/* Location Button */}
          <button
            onClick={toggleLocationBar}
            className="text-white hover:opacity-80 transition-opacity focus:outline-none"
            aria-label="Mostrar ubicación"
          >
            <MapPin className="w-6 h-6" />
          </button>
        </nav>

        {/* Location Bar */}
        <div 
          className={`w-full bg-[#09fdfd] text-slate-900 font-base transition-all duration-300 ease-in-out overflow-hidden ${
            showLocationBar ? 'max-h-12 py-2' : 'max-h-0 py-0'
          }`}
        >
          <div className="container mx-auto px-6 flex items-center justify-center">
            <MapPin className="w-5 h-5 mr-2" />
            <span>Ubicación: {userLocation}</span>
          </div>
        </div>

        {/* Progress bar indicator */}
        <div className="h-0.5 bg-primary" />
      </header>

      <MobileMenu 
        isOpen={isMenuOpen}
        onClose={toggleMenu}
        user={user}
      />
    </>
  );
};

export default Header;