import axios from 'axios';

const API_URL = 'https://findout-adf55aa841e8.herokuapp.com/api';

export const pageService = {
  async getPageBySlug(slug) {
    try {
      const response = await axios.get(`${API_URL}/pages/${slug}/`);
      return response.data;
    } catch (error) {
      console.error('Error fetching page:', error);
      throw error;
    }
  }
};
