import axios from 'axios';

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

// Cache para almacenar las respuestas
const responseCache = new Map();

export const axiosWithRetry = async (url, options = {}, maxRetries = 3, initialDelay = 1000) => {
  // Generar una clave única para el cache basada en la URL y el método
  const cacheKey = `${url}-${options.method || 'GET'}`;
  
  // Si la respuesta está en caché y no ha expirado, devolverla
  if (responseCache.has(cacheKey)) {
    return responseCache.get(cacheKey);
  }

  let lastError;
  let retryCount = 0;

  while (retryCount < maxRetries) {
    try {
      // Usar el método HTTP correcto desde las opciones
      const method = (options.method || 'GET').toLowerCase();
      const response = await axios({
        ...options,
        method,
        url
      });

      // Guardar la respuesta en el cache
      responseCache.set(cacheKey, response);
      
      return response;
    } catch (error) {
      lastError = error;
      
      // Si el error no es 500 o ya hemos intentado el máximo de veces, lanzar el error
      if (error.response?.status !== 500 || retryCount === maxRetries - 1) {
        throw error;
      }

      // Esperar antes de reintentar (con backoff exponencial)
      const waitTime = initialDelay * Math.pow(2, retryCount);
      await delay(waitTime);
      retryCount++;
    }
  }

  throw lastError;
};
