import React, { useState } from 'react';

const CacheManager = () => {
  const [isClearing, setIsClearing] = useState(false);
  const [status, setStatus] = useState('');

  const clearCache = async () => {
    setIsClearing(true);
    setStatus('Iniciando limpieza de caché...');
    try {
      // Limpiar caché del navegador
      setStatus('Limpiando caché del navegador...');
      const cacheNames = await caches.keys();
      await Promise.all(cacheNames.map(name => caches.delete(name)));

      // Enviar mensaje al Service Worker
      setStatus('Actualizando Service Worker...');
      if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage('CLEAR_CACHE');
      }

      // Registrar nuevo Service Worker
      if ('serviceWorker' in navigator) {
        const registrations = await navigator.serviceWorker.getRegistrations();
        await Promise.all(registrations.map(registration => registration.unregister()));
      }

      setStatus('Caché limpiada exitosamente. Recargando en 3 segundos...');

      // Recargar la página después de un breve retraso
      setTimeout(() => {
        window.location.reload(true);
      }, 3000);
    } catch (error) {
      console.error('Error al limpiar caché:', error);
      setStatus('Error al limpiar caché: ' + error.message);
    } finally {
      setIsClearing(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md mx-auto bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">
            Gestión de Caché
          </h2>
          
          <div className="space-y-4">
            <p className="text-gray-600 dark:text-gray-300">
              Usa esta herramienta para forzar una actualización de la aplicación y limpiar la caché.
              Esto asegurará que todos los usuarios vean la última versión.
            </p>

            <div className="bg-gray-100 dark:bg-gray-700 rounded-lg p-4 mt-4">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
                Estado Actual
              </h3>
              <p className="text-gray-600 dark:text-gray-300">
                {status || 'Listo para actualizar'}
              </p>
            </div>

            <button
              onClick={clearCache}
              disabled={isClearing}
              className="w-full bg-[#09FDFD] text-white px-6 py-3 rounded-lg shadow-lg hover:bg-[#00d8d8] transition-all duration-300 flex items-center justify-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isClearing ? (
                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                </svg>
              )}
              {isClearing ? 'Limpiando caché...' : 'Actualizar Aplicación'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CacheManager;
