import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCloudinaryUrl } from '../utils/cloudinary';

const CustomPageCompanyCard = ({ company }) => {
  const navigate = useNavigate();
  const [imageLoaded, setImageLoaded] = useState(false);

  if (!company || typeof company !== 'object') {
    return null;
  }

  const {
    id,
    name,
    cover_photo,
    profile_picture,
    badges
  } = company;

  const coverImage = cover_photo || '';
  const logoImage = profile_picture || '';
  
  const handleClick = () => {
    navigate(`/company/${id}`);
  };

  return (
    <div onClick={handleClick} className="text-center relative font-system">
      <div className="block relative">
        {/* Skeleton loader */}
        {!imageLoaded && (
          <div className="w-full aspect-square bg-gray-200 dark:bg-gray-700 animate-pulse rounded-lg"></div>
        )}

        {/* Main image container with fixed aspect ratio */}
        <div className="relative aspect-square w-full">
          <img 
            src={getCloudinaryUrl(coverImage)}
            alt={name || 'Company cover'}
            className={`w-full h-full object-cover rounded-lg ${!imageLoaded ? 'hidden' : ''}`}
            onLoad={() => setImageLoaded(true)}
          />
        </div>

        {/* Company logo */}
        {logoImage && imageLoaded && (
          <img 
            src={getCloudinaryUrl(logoImage)}
            alt={`${name} logo`}
            className="absolute top-2 left-2 border-2 border-inherit w-[35px] h-[35px] rounded-full object-cover shadow-[inset_0_1px_2px_0_rgba(60,64,67,0.3),inset_0_2px_6px_2px_rgba(60,64,67,0.15)]"
          />
        )}

        {/* Company name */}
        {imageLoaded && (
          <p className="font-extrabold mt-1 text-sm text-neutral-700 dark:text-neutral-300 leading-4 flex items-center justify-center">
            <span className="inline-block min-w-[0.375rem] min-h-[0.375rem] w-1.5 h-1.5 aspect-square bg-primary dark:bg-[#09fdfd] rounded-full mr-1"></span>
            {name}
          </p>
        )}
      </div>
    </div>
  );
};

export default CustomPageCompanyCard;
