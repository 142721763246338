import React from 'react';
import { Heart, Star } from 'lucide-react';

const JoinFindoutPage = () => {
  const benefits = [
    {
      text: "Tu negocio, producto y servicio se mostrarán en el algoritmo de búsqueda"
    },
    {
      text: "Añade un menú de tus productos / servicios"
    },
    {
      text: "Recibe insignias por tus valoraciones como negocio"
    },
    {
      text: "Accede a promociones para ser destacar en la seccion principal"
    }
  ];

  return (
    <div className="fixed inset-0 z-20 flex flex-col mt-[44px] bg-gray-100">
      <div className="flex-1 overflow-auto bg-gradient-to-b from-gray-100 dark:from-gray-900 from-0% via-gray-100 dark:via-gray-900 via-60% to-[#09FDFD] dark:to-[#077f7f] to-100%">
        <div className="container mx-auto px-6 py-6 max-w-lg">
          {/* Estrella animada */}
          <div className="relative w-full flex justify-center items-center mb-4 mt-2">
            <div className="relative w-20 h-20 flex items-center justify-center">
              <Star 
                className="w-16 h-16 text-[#09FDFD] dark:text-[#077f7f]"
                strokeWidth={1.2}
                style={{
                  filter: 'drop-shadow(0 0 2px #09FDFD)'
                }}
              />
            </div>
          </div>

          {/* Main Text */}
          <div className="text-center space-y-2 mb-6">
            <h1 className="text-2xl font-bold text-gray-900 dark:text-white leading-7">
              Deja que tu negocio sea descubierto por la comunidad hispana
            </h1>
            <p className="text-gray-600 dark:text-gray-400 leading-5 text-sm">
              Y explora beneficios según la categoría de tu negocio
            </p>
          </div>

          {/* Benefits List */}
          <div className="space-y-4 mb-8">
            {benefits.map((benefit, index) => (
              <div key={index} className="flex items-center space-x-3">
                <div className="flex-shrink-0 w-6 h-6 shadow-md rounded-full bg-[#09FDFD] dark:bg-[#077f7f] flex items-center justify-center">
                  <Star className="w-4 h-4 text-white" />
                </div>
                <p className="text-gray-700 dark:text-gray-300 leading-5 text-sm">{benefit.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Join Button */}
      <div className="fixed bottom-0 left-0 right-0 pb-6 pt-4 bg-gradient-to-t from-[#09FDFD] dark:from-[#077f7f] to-transparent">
        <div className="container mx-auto px-6 max-w-lg">
          <a 
            href="https://wa.me/15103853094" 
            target="_blank" 
            rel="noopener noreferrer"
            className="relative w-full bg-black dark:bg-white text-white dark:text-black py-4 rounded-full font-medium flex items-center justify-center space-x-2 transition-transform hover:scale-105"
          >
            <span>Unirme a Findout</span>
            <Heart className="w-5 h-5 text-[#09FDFD] dark:text-[#077f7f]" />
          </a>
          <p className="text-center text-gray-900 dark:text-white font-medium mt-2">
            Por solo <span className="text-gray-900 dark:text-white font-bold text-xl">$9,99</span> / mes
          </p>
        </div>
      </div>
    </div>
  );
};

export default JoinFindoutPage;