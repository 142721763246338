import React, { useState, useEffect, useMemo } from 'react';
import { axiosWithRetry } from '../utils/axiosRetry';

const TopBurgers = ({ companies }) => {
  const [sections, setSections] = useState([]);
  const [error, setError] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isIOS, setIsIOS] = useState(false);

  useEffect(() => {
    const checkIsIOS = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    };
    setIsIOS(checkIsIOS());

    const fetchData = async () => {
      try {
        const token = localStorage.getItem('authToken');
        const config = { 
          headers: { 
            Authorization: token ? `Bearer ${token}` : undefined,
            'Content-Type': 'application/json'
          }
        };
        
        const response = await axiosWithRetry('https://findout-adf55aa841e8.herokuapp.com/api/top-burgers/', config);
        setSections(response.data);
      } catch (error) {
        console.error('Error al cargar datos de hamburguesas:', error.response?.data?.error || error.message);
        setError('No se pudieron cargar los datos. Por favor, intenta de nuevo más tarde.');
      }
    };

    fetchData();

    const darkModeEnabled = localStorage.getItem('darkMode') === 'true';
    setIsDarkMode(darkModeEnabled);

    const handleDarkModeChange = (e) => {
      setIsDarkMode(e.matches);
    };

    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    darkModeMediaQuery.addListener(handleDarkModeChange);

    return () => {
      darkModeMediaQuery.removeListener(handleDarkModeChange);
    };
  }, []);

  const isFullWidthBanner = (section) => {
    return section.items.length === 1 && section.items[0].item_type === 'BANNER';
  };

  const renderItem = (item, isFullWidth = false) => {
    if (isFullWidth) {
      return (
        <div className="relative w-full">
          <a href={item.custom_url} className="block">
            <img
              src={item.featured_image}
              alt="Banner"
              className="w-full h-auto object-cover"
              loading="lazy"
            />
          </a>
        </div>
      );
    }

    return (
      <div key={item.order} className="text-center relative font-system">
        <a 
          href={item.item_type === 'BANNER' ? item.custom_url : item.company_profile_url}
          className="block relative"
        >
          <img
            src={item.featured_image}
            alt={item.item_type === 'BANNER' ? 'Banner' : item.company_name}
            className="w-full h-auto rounded-lg"
            loading="lazy"
          />
          {item.item_type === 'COMPANY' && (
            <>
              <img 
                src={item.company_logo}
                alt={`${item.company_name} logo`}
                className="absolute top-2 left-2 border-2 border-inherit w-[35px] h-[35px] rounded-full object-cover shadow-[inset_0_1px_2px_0_rgba(60,64,67,0.3),inset_0_2px_6px_2px_rgba(60,64,67,0.15)]"
                loading="lazy"
              />
              <p className="font-extrabold mt-1 text-sm text-neutral-700 dark:text-neutral-300 leading-4 flex items-center justify-center">
                <span className="inline-block w-1.5 h-1.5 bg-primary dark:bg-[#09fdfd] rounded-full mr-1"></span>
                {item.company_name}
              </p>
            </>
          )}
        </a>
      </div>
    );
  };

  if (error) return <div className="font-system dark:text-white">{error}</div>;
  if (!sections.length) return null;

  return (
    <div className={`flex flex-col gap-1 font-system ${isDarkMode ? 'dark' : ''}`}>
      {sections.map((section) => {
        const fullWidthBanner = isFullWidthBanner(section);
        
        if (fullWidthBanner) {
          return (
            <section 
              key={section.title} 
              className="relative w-full pb-2 pt-1"
            >
              <div className={`${isIOS ? 'w-full overflow-hidden' : 'w-screen relative left-1/2 right-1/2 -mx-[50vw]'}`}
                   style={isIOS ? {
                     width: '100vw',
                     marginLeft: 'calc(-50vw + 50%)',
                     marginRight: 'calc(-50vw + 50%)'
                   } : {}}
              >
                {renderItem(section.items[0], true)}
              </div>
            </section>
          );
        }

        return (
          <section key={section.title} className="flex flex-col items-center w-full">
            <div className="flex items-center gap-2 w-full mb-2">
              <h3 className="font-extrabold m-0 text-xl text-neutral-700 dark:text-neutral-300 flex items-center">
                {section.texto_azul ? (
                  <>
                    {section.title}{' '}
                    <span className="text-slate-900 dark:text-[#09fdfd] ml-1">
                      {section.texto_azul}
                    </span>
                  </>
                ) : (
                  section.title
                )}
                {section.location && (
                  <span className="font-normal text-gray-400 text-lg ml-2 flex items-center">
                    {section.location} <span className="text-[#09fdfd] ml-1 text-xl">›</span>
                  </span>
                )}
              </h3>
            </div>
            <div className="grid grid-cols-3 gap-2 w-full">
              {section.items.map((item) => (
                <div key={item.order}>
                  {renderItem(item)}
                </div>
              ))}
            </div>
          </section>
        );
      })}
    </div>
  );
};

export default TopBurgers;