import React, { useState, useEffect } from 'react';
import { axiosWithRetry } from '../utils/axiosRetry';
import MenuBar from './MenuBar';
import { Link } from 'react-router-dom';
import TopBurgers from './TopBurguers';
import Slider from './Slider';
import PersonalizedCategorySlider from './PersonalizedCategorySlider';
import MiniSearch from './MiniSearch';

const CompanyList = () => {
  const [companies, setCompanies] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!companies.length) {
        try {
          const token = localStorage.getItem('authToken');
          const config = { 
            headers: { 
              Authorization: token ? `Bearer ${token}` : undefined,
              'Content-Type': 'application/json'
            }
          };

          const response = await axiosWithRetry('https://findout-adf55aa841e8.herokuapp.com/api/companies/', config);
          setCompanies(response.data);
          setError(null);
        } catch (error) {
          console.error('Error al cargar empresas:', error.response?.data?.error || error.message);
          setError('No se pudieron cargar los datos. Por favor, intenta de nuevo más tarde.');
          setCompanies([]);
        }
      }
    };

    fetchData();
  }, []);

  if (error) {
    return (
      <div className="container mx-auto px-3 font-system">
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-4" role="alert">
          <strong className="font-bold">¡Error! </strong>
          <span className="block sm:inline">{error}</span>
          <button 
            onClick={() => window.location.reload()} 
            className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded mt-2"
          >
            Intentar de nuevo
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-3 font-system">
      <div className="mb-2">
        <MiniSearch />
      </div>
      
      <Slider />
      
      <PersonalizedCategorySlider />
   
      <div className="my-4">
        <TopBurgers companies={companies} />
      </div>
      
      <MenuBar />
    </div>
  );
};

export default CompanyList;